.table {
  width: inherit;
  border: 1.2pt solid #000;
}

.table .table_header {
  text-align: center;
  border-bottom: 1.2pt solid #000;
  align-items: center;
  display: flex;
}

.table .table_header .table_header_hour {
  width: 30pt;
  padding: 0 7pt;
  font-size: 14pt;
}

.table .table_header .table_header_col_wrap {
  width: 100%;
  display: flex;
}

.table .table_header .table_header_col_wrap .table_header_col {
  border-left: 1.2pt solid #000;
  font-size: 14pt;
}

.table .table_header .table_header_col_wrap .table_header_col .day_name {
  font-weight: bold;
}

.table .table_header .table_header_col_wrap .table_header_col .day {
  font-size: 11pt;
}

.table .table_header .table_header_col_wrap .table_header_col.weekday {
  width: 38.1%;
}

.table .table_header .table_header_col_wrap .table_header_col.saturday {
  width: 33.33%;
}

.table .table_header .table_header_col_wrap .table_header_col.sunday {
  width: 28.57%;
}

.table .table_header .table_header_hour_right {
  border-left: 1.2pt solid #000;
  width: 30pt;
  padding: 0 7pt;
  font-size: 14pt;
}

.table .hour_group {
  text-align: center;
  border-bottom: 1.2pt solid #000;
  align-content: center;
  align-items: center;
  display: flex;
}

.table .hour_group:last-child {
  border-bottom: unset;
}

.table .hour_group .hour {
  width: 30pt;
  padding: 0 4pt;
  font-size: 14pt;
  font-weight: bold;
}

.table .hour_group .hour_right {
  border-left: 1.2pt solid #000;
  width: 30pt;
  padding: 0 4pt;
  font-size: 14pt;
  font-weight: bold;
}

.table .hour_group .hour_right .center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.table .hour_group .minutes_group {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.table .hour_group .minutes_group .minutes {
  border-left: 1.2pt solid #000;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 2pt 0;
  min-height: 30pt;
  padding: 0 1pt 1pt;
  display: flex;
}

.table .hour_group .minutes_group .minutes:empty {
  gap: unset;
}

.table .hour_group .minutes_group .minutes.weekday {
  width: 38.1%;
}

.table .hour_group .minutes_group .minutes.weekday_0 {
  background-color: #efefef;
}

.table .hour_group .minutes_group .minutes.weekday_1 {
  background-color: #dcdcdc;
}

.table .hour_group .minutes_group .minutes.saturday {
  width: 33.33%;
}

.table .hour_group .minutes_group .minutes.saturday_0 {
  background-color: #d4ebff;
}

.table .hour_group .minutes_group .minutes.saturday_1 {
  background-color: #bce1ff;
}

.table .hour_group .minutes_group .minutes.sunday {
  width: 28.57%;
}

.table .hour_group .minutes_group .minutes.sunday_0 {
  background-color: #ffcee6;
}

.table .hour_group .minutes_group .minutes.sunday_1 {
  background-color: #feb5d8;
}

.table .hour_group .minutes_group .minutes.minute_width_0 {
  padding-left: 0;
}

.table .hour_group .minutes_group .minutes.minute_width_0 .minute_wrap {
  margin-left: 0;
}

.table .hour_group .minutes_group .minutes.minute_width_1 {
  padding-left: 2pt;
}

.table .hour_group .minutes_group .minutes.minute_width_1 .minute_wrap {
  margin-left: 2pt;
}

.table .hour_group .minutes_group .minutes.minute_width_2 {
  padding-left: 0;
}

.table .hour_group .minutes_group .minutes.minute_width_2 .minute_wrap {
  margin-left: 7pt;
}

.table .hour_group .minutes_group .minutes .minute_wrap {
  width: 44pt;
  position: relative;
}

.table .hour_group .minutes_group .minutes .minute_wrap .minute {
  text-align: center;
  font-size: 16pt;
  font-weight: bold;
}

.table .hour_group .minutes_group .minutes .minute_wrap .minute.long_time {
  color: #333;
  font-weight: 400;
  text-decoration: underline;
}

.table .hour_group .minutes_group .minutes .minute_wrap .company_name {
  height: 12pt;
  font-size: 10pt;
  text-decoration: underline;
  position: absolute;
  transform: translate(-1pt, 5.5pt);
}

.table .hour_group .minutes_group .minutes .minute_wrap .stop_platform_code {
  font-size: 11pt;
  position: absolute;
  right: 0;
  transform: translate(1pt, 4pt);
}

.table .hour_group .minutes_group .minutes .minute_wrap .route_name {
  margin-top: -5pt;
  font-size: 8pt;
}

.table .hour_group .minutes_group .minutes .minute_wrap .route_id {
  margin-top: -2pt;
  font-size: 8pt;
}

/*# sourceMappingURL=index.20ebe387.css.map */
